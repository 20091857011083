/* .dataresorce-img>div {height: 100%;} */

/* .dataresource_slide .slick-slide.slick-active.slick-center.slick-current {

} */

.dataresource_slide .slick-slider .slick-list {
    /* padding-top: 30px !important;
    padding-bottom: 45px !important; */
    overflow-x: hidden;
}
.dataresource_slide .slick-slider.slick-initialized .slick-list .slick-slide {
    padding-top: 40px !important;
    padding-bottom: 45px !important;
}

.dataresource_slide .slick-slide.slick-active.slick-center.slick-current .dataresource_box {
    box-shadow: 0px 10px 40px 0px #00000059;
}

.dataresource_slide .slick-slide.slick-active.slick-center.slick-current .dataresource_box .resorce_btn {
    display: inline-block;
}
/* shadow-[0px_10px_80px_0px_#00000059] */

/* .slick-initialized .slick-slide > div {margin-bottom: 0px !important;} */

.dataresource_slide .slick-prev {left: 15px;z-index: 1;}
.dataresource_slide .slick-next {right: 15px;z-index: 1;}
.dataresource_slide .slick-prev:before, .dataresource_slide .slick-next:before {
    color: #0035ff;    font-size: 30px;
}